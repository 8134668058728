import React from 'react';
import { graphql } from 'gatsby';

import Link from '../components/LocalizedLink';
import withLayout from '../layout';
import SEO from '../components/seo';

import './tagsTemplate.css';


const Tags = ({ pageContext, data }) => {
    const { tag } = pageContext;
    const { edges, totalCount } = data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? '' : 's'
    } tagged with "${tag}"`;

    return (
        <div>
            <SEO title="Tags" />
            <div>
                <h1>{tagHeader}</h1>
                <ul>
                    {edges.map(({ node }) => {
                        const { title, date } = node.frontmatter;
                        const { slug } = node.fields;

                        return (
                            <li key={slug}>
                                <Link to={slug}>
                                    {title} ({date})
                                </Link>
                            </li>
                        );
                    })}
                </ul>
                <Link to="/tags">All tags</Link>
            </div>
        </div>
    );
};

const customProps = {
    localeKey: 'tags',
};

export default withLayout(customProps)(Tags);

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
